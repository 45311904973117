import React from 'react';
import Moment from 'moment';
import { Link } from 'react-router-dom';
const { NEWS } = require('./../data/news');

const News = (props) => {
	window.scrollTo(0, Math.random() * 10);window.scrollTo(0, 0);
	const f = (d) => (
		<>
			<span>{ Moment(d).format('Do') }</span>
			<span>{ Moment(d).format('MMM') }</span>
			<span>{ Moment(d).format('Y') }</span>
		</>
	);
	return (
		<main>
			{ NEWS.map(({ date, body, slug, className, image }) => (
			<section key={ date } className={ `${className || ''} picture` }>
				<div className="appear">
					<img src={ image.src } alt={ image.alt } />
					<h3>{ slug ? <Link to={ slug }>{ f(date) }</Link> : f(date) }</h3>
					{ body }
				</div>
			</section>
			)) }
		</main>
	);
};

export default News;
