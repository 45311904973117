// import React from 'react';
// import Moment from 'moment';
// import { Link } from 'react-router-dom';
const { DRUM_LESSONS } = require('./../data/drum-lessons');

const DrumLessons = (props) => {
	window.scrollTo(0, Math.random() * 10);window.scrollTo(0, 0);
	// const f = (d) => (
	// 	<>
	// 		<span>{ Moment(d).format('Do') }</span>
	// 		<span>{ Moment(d).format('MMM') }</span>
	// 		<span>{ Moment(d).format('Y') }</span>
	// 	</>
	// );
	return (
		<main>
			{ DRUM_LESSONS.map(({ title, body, className, media }) => (
			<section key={ title } className={ `${className || ''} lesson` }>
				<div className="__appear">
					<h3>{ title }</h3>
					{ media.map(({title, src}) => <>
						<h4>{ title }</h4>
						<video src={ src } _poster="movie.jpg" controls />
					</> )}
					{ body }
				</div>
			</section>
			)) }
		</main>
	);
};

export default DrumLessons;
