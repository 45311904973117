export const GALLERIES = {
	people: {
		description: 'Faces: Everyone\'s got one, and some people have more than one.',
		slug: '/people',
		title: 'People',
		columns: 7,
		galleries: [ {
			title: 'People',
			images: [
			{
				title: 'Keith',
				description:'Keith Richards: Oil on canvas. 2018',
				img: { alt: 'Keith', src: '/img/Keith.jpg' },
				li: {	style: {	gridColumn: 'span 3', gridRow: 'span 3' } }
			},
			{
				title: 'Harold',
				description:'Harold Lloyd: Oil on canvas. 2017',
				img: { alt: 'Harold', src: '/img/Harold.jpg' },
				li: {	style: {	gridColumn: 'span 2', gridRow: 'span 2' } }
			},
			{
				title: 'Stan and Ollie',
				description:'Stan Laurel and Oliver Hardy: Oil on canvas. 2019',
				img: { alt: 'Stan and Ollie', src: '/img/Stan-and-Ollie.jpg', title: 'Stan and Ollie. Oil on canvas. 2017' },
				li: {	style: {	gridColumn: 'span 2', gridRow: 'span 1' } }
			},
			{
				title: 'Charlie',
				description:'Charlie Chaplin: Oil on canvas. 2018',
				img: { alt: 'Charlie', src: '/img/Charlie.jpg' },
				li: {	style: {	gridColumn: 'span 1', gridRow: 'span 2' } }
			},
			{
				title: 'Kurt',
				description:'Kurt Barlow: Oil on canvas. 2018',
				img: { alt: 'Kurt', src: '/img/Kurt.jpg' },
				li: {	style: {	gridColumn: 'span 1', gridRow: 'span 1' } }
			},
			{
				title: 'Lillian',
				description:'Lillian Gish: Oil on canvas. 2018',
				img: { alt: 'Lillian', src: '/img/Lillian.jpg' },
				li: {	style: {	gridColumn: 'span 1', gridRow: 'span 1' } }
			},
			{
				title: 'Kevin',
				description:'Kevin McCarthy from Invasion of the Body Snatchers: Oil on canvas. 2019',
				img: { alt: 'Kevin', src: '/img/Kevin.jpg' },
				li: {	style: {	gridColumn: 'span 1', gridRow: 'span 1' } }
			},
			{
				title: 'Winston',
				description:'Winston Churchill: Oil on canvas. 2019',
				img: { alt: 'Winston', src: '/img/Winston.jpg' },
				li: {	style: {	gridColumn: 'span 1', gridRow: 'span 1' } }
			},
			{
				title: 'Ava',
				description:'Ava: Oil on canvas. 2021',
				img: { alt: 'Ava', src: '/img/Ava.jpg' },
				li: { style: { gridColumn: 'span 2', gridRow: 'span 2' } }
			},
			{
				title: 'Debbie',
				description:'Debbie Harry: Oil on canvas. 2018',
				img: { alt: 'Debbie', src: '/img/Debbie.jpg' },
				li: {	style: {	gridColumn: 'span 1', gridRow: 'span 1' } }
			},
			{
				title: 'James',
				description:'James Dean: Oil on canvas. 2020',
				img: { alt: 'James', src: '/img/James.jpg' },
				li: {	style: {	gridColumn: 'span 1', gridRow: 'span 1' } }
			},
			{
				title: 'Ron',
				description:'Ron: Oil on canvas. 2021',
				img: { alt: 'Ron', src: '/img/Ron.jpg' },
				li: { style: { gridColumn: 'span 2', gridRow: 'span 2' } }
			},
			// {
			// 	title: 'Spock',
			// 	description:'Leonard Nimoy as Spock: Oil on canvas. 2016',
			// 	img: { alt: 'Spock', src: '/img/Spock.jpg' },
			// 	li: { style: { gridColumn: 'span 1', gridRow: 'span 1' } }
			// },
			// {
			// 	title: 'Audrey',
			// 	description:'Audrey Hepburn as Natasha Rostova: Oil on canvas. 2016',
			// 	img: { alt: 'Audrey', src: '/img/Audrey.jpg' },
			// 	li: { style: { gridColumn: 'span 1', gridRow: 'span 1' } }
			// },
			// {
			// 	title: 'Arnold',
			// 	description:'Arnold Schwarzenegger as The Terminator: Oil on canvas. 2016',
			// 	img: { alt: 'Arnold', src: '/img/Arnold.jpg' },
			// 	li: { style: { gridColumn: 'span 1', gridRow: 'span 1' } }
			// },
			// {
			// 	title: 'Doris',
			// 	description:'Doris Day as Calamity Jane: Oil on canvas. 2018',
			// 	img: { alt: 'Doris', src: '/img/Doris.jpg' },
			// 	li: { style: { gridColumn: 'span 1', gridRow: 'span 1' } }
			// },
			// {
			// 	title: 'David',
			// 	description:'David Niven as Raffles: Oil on canvas. 2018',
			// 	img: { alt: 'David', src: '/img/David.jpg' },
			// 	li: { style: { gridColumn: 'span 1', gridRow: 'span 1' } }
			// },
		]
		} ]
	},
	places: {
		description: 'Dig a deep enough tunnel from the UK, eventually you come out in New Zealand - which is where I live now.',
		slug: '/places',
		title: 'Places',
		columns: 24,
		galleries: [ {
			title: 'Places',
			images: [
			{
				title: 'Moreton Corbett',
				description: 'Moreton Corbett Manor, Shropshire. Oil on board. 1998/2020',
				img: { alt: 'Moreton Corbett', src: '/img/Moreton-Corbett.jpg' },
				li: { style: { gridColumn: 'span 8', gridRow: 'span 4', border: '0px solid #607d8b' } }
			},
			{
				title: 'Wellington',
				description: 'Wellington. Oil on canvas. 2020',
				img: { alt: 'Wellington', src: '/img/Wellington.jpg' },
				li: { style: { gridColumn: 'span 9', gridRow: 'span 3', border: '0px solid silver' } }
			},
			{
				title: 'Grope Lane',
				description: 'Grope Lane, Shrewsbury. Oil on canvas. 1996',
				img: { alt: 'Grope Lane', src: '/img/Grope-Lane.jpg' },
				li: { style: { gridColumn: 'span 7', gridRow: 'span 3', border: '0px solid #607d8b' } }
			},
			{
				title: 'Wyle Cop',
				description: 'Wyle Cop, Shrewsbury. Oil on canvas. 1997',
				img: { alt: 'Wyle Cop', src: '/img/Wyle-Cop.jpg', title: 'Wyle Cop. Oil on board. 1997' },
				li: { style: { gridColumn: 'span 5', gridRow: 'span 1', border: '0px solid #1b2340' } }
			},
			{
				title: 'St Alkmunds',
				description: 'St Alkmunds, Shrewsbury. Oil on canvas. 2000',
				img: { alt: 'St Alkmunds', src: '/img/St-Alkmunds.jpg' },
				li: { style: { gridColumn: 'span 6', gridRow: 'span 1', border: '0px solid #2b2a2f' } }
			},
			{
				title: 'Alex Hall',
				description: 'Alex Hall, Aberystwyth. Oil on board. c.1995',
				img: { alt: 'Alex Hall', src: '/img/Alex-Hall.jpg' },
				li: { style: { gridColumn: 'span 5', gridRow: 'span 1', border: '0px solid #485128' } }
			},
			{
				title: 'The Mander Centre',
				description: 'The Mander Centre, Wolverhampton. Oil on canvas. c.2010',
				img: { alt: 'The Mander Centre', src: '/img/The-Mander-Centre.jpg' },
				li: { style: { gridColumn: 'span 9', gridRow: 'span 1', border: '0px solid #3a4640' } }
			},
			{
				img: { alt: 'Shrewsbury Clock Tower', src: '/img/Shrewsbury-Clock-Tower.jpg' },
				li: { style: { gridColumn: 'span 6', gridRow: 'span 1', border: '0px solid silver' } }
			},
			{
				title: 'The River',
				description: 'The River Severn, Shrewsbury. Oil on canvas. 2004',
				img: { alt: 'The River', src: '/img/The-River.jpg' },
				li: { style: { gridColumn: 'span 9', gridRow: 'span 1', border: '0px solid #0b253c' } }
			},
			{
				img: { alt: 'Welcome to Britain', src: '/img/Welcome-to-Britain.jpg' },
				li: { style: { gridColumn: 'span 8', gridRow: 'span 1', border: '0px solid #1f373f' } }
			},
			{
				title: 'Cowmute',
				description:'Auckland Commuters: Oil on canvas. 2017',
				img: { alt: 'Cowmute', src: '/img/Cowmute.jpg' },
				li: { style: { gridColumn: 'span 8', gridRow: 'span 1', border: '0px solid silver' } }
			},
			{
				id: 'freedom-for-the-trees',
				title: 'Freedom for the Trees',
				description: 'Uprooting is hard and flying, even harder. Oil on canvas 2001',
				img: { alt: 'Freedom for the Trees', src: '/img/Freedom-for-the-Trees.jpg' },
				li: { style: { gridColumn: 'span 8', gridRow: 'span 1', border: '0px solid silver' } }
			},
			// {
			// 	img: { alt: 'Fish Street', src: '/img/Fish-Street.jpg' },
			// 	li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
			// },
		]
		} ]
	},
	other: {
		description: 'Other creatures from somewhere or other.',
		slug: '/other',
		title: 'Other',
		columns: 20,
		galleries: [
			{
				title: 'Oranges',
				description: 'A study of something we don\'t talk about',
				images: [
					{
						title: 'Over Compensating',
						description: 'Bury those tears deep down. Oil on canvas. 2020',
						img: { alt: 'Over Compensating', src: '/img/D3-Over-Compensating.jpg' },
						li: { style: { gridColumn: 'span 8', gridRow: 'span 3' } }
					},
					{
						title: 'Hiding',
						description: 'Don\'t move. The normals\' vision is based on movement. Oil on canvas. 2020',
						img: { alt: 'Hiding', src: '/img/D1-Hiding.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						title: 'Pretending',
						description: 'I\'m just like the rest of you. Oil on canvas. 2020',
						img: { alt: 'Pretending', src: '/img/D2-Pretending.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						title: 'Debbie Too',
						description: 'Debbie Harry! Oil on canvas. 2020',
						img: { alt: 'Debbie-2', src: '/img/Debbie-2.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						title: 'The Fighter',
						description: 'Studio 1! Oil on canvas. 1999',
						img: { alt: 'The Fighter', src: '/img/The-Fighter.jpg' },
						li: { style: { gridColumn: 'span 6', gridRow: 'span 2' } }
					},
					{
						img: { alt: 'Maximillion', src: '/img/Max.jpg' },
						li: { style: { gridColumn: 'span 6', gridRow: 'span 2' } }
					}
				]
			},
			{
				title: 'Bs',
				description: '',
				images: [
					{
						img: { alt: 'B1', src: '/img/B1.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						img: { alt: 'B2', src: '/img/B2.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						img: { alt: 'B3', src: '/img/B3.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						img: { alt: 'B4', src: '/img/B4.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						img: { alt: 'B5', src: '/img/B5.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					/* {
						img: { alt: 'LV-426', src: '/img/20200816_153701.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						img: { alt: 'LV-427', src: '/img/20200816_153706.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						img: { alt: 'Them and Us', src: '/img/Them-and-Us.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},
					{
						img: { alt: 'Rotary Mark', src: '/img/Rotary-Mark.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
					},*/
				]
			},
			// {
			// 	title: 'Misc',
			// 	description: '',
			// 	images: [
			// 		{
			// 			img: { alt: 'B1', src: '/img/Terrys-Bike.jpg' },
			// 			li: { style: { gridColumn: 'span 4', gridRow: 'span 1' } }
			// 		}
			// 	]
			// }
		]
	},
	cartoons: {
		description: 'Cartoons the Kids Cartoon Club on Saturdays and more',
		slug: '/cartoons',
		title: 'Cartoons',
		columns: 20,
		galleries: [
			{
				title: 'To Covid, or not to Covid',
				description: 'A selection of cartoons about the current nonsense',
				images: [
					{
						title: 'Wolf in Sheep\'s Clothing.',
						description: 'Mary Poppins of New Zealand government is not all she seems.',
						img: { alt: 'Wolf in Sheep\'s Clothing', src: '/img/Cartoon-Wolf-in-Sheeps-Clothing.jpg' },
						li: { style: { gridColumn: 'span 8', gridRow: 'span 2' } }
					},
					{
						title: 'Maria Jacinda Poppins and her control of the media.',
						description: 'Notice that she too has strings...',
						img: { alt: 'The Government Statistician', src: '/img/Cartoon-Punch-and-Judy-News.jpg' },
						li: { style: { gridColumn: 'span 12', gridRow: 'span 3' } }
					},
					{
						title: 'A Spoonfull of Sugar Helps the Medicine Go Down',
						description: 'Mary Poppins wannabe Jacinda Ardern has lost the plot here in NZ. NZ Apartheid and removal of basic freedoms.',
						img: { alt: 'A Spoonfull of Sugar Helps the Medicine Go Down', src: '/img/Cartoon-Jacinda-Loses-the-Plot.jpg' },
						li: { style: { gridColumn: 'span 8', gridRow: 'span 2' } }
					},
					{
						title: 'Jabcinda Park',
						description: 'No jab, no job insanity. It is a Brave New World and there is trouble in paradise.',
						img: { alt: 'Jabcinda Park', src: '/img/Cartoon-Jabcinda-Park.jpg' },
						li: { style: { gridColumn: 'span 6', gridRow: 'span 2' } }
					},
					{
						title: 'The Government Statistician',
						description: 'Dispelling the mystery of where the government get their statistics from.',
						img: { alt: 'The Government Statistician', src: '/img/Cartoon-Government-Statistician.jpg' },
						li: { style: { gridColumn: 'span 6', gridRow: 'span 2' } }
					}
				]
			},
			{
				title: 'Cartoons',
				description: 'A selection of cartoons from Arapuni Art and beyond',
				images: [
					{
						title: 'Unaccountability',
						description: 'Following the announcement of a potential 18.5% rates increase in the Waikato.',
						img: { alt: 'Cones4u Cartoon', src: '/img/Cartoon-Cones4u.jpg' },
						li: { style: { gridColumn: 'span 11', gridRow: 'span 2' } }
					},
					{
						title: 'Oliver Twist and the Mayor(ess)',
						description: '',
						img: { alt: 'Cartoon 7', src: '/img/Cartoon-Oliver-Twist.jpg' },
						li: { style: { gridColumn: 'span 9', gridRow: 'span 2' } }
					},
					{
						title: 'Cartoon Super Heroine',
						description: '',
						img: { alt: 'Cartoon 4', src: '/img/Cartoon-Super-Heroine.png' },
						li: { style: { gridColumn: 'span 3', gridRow: 'span 4' } }
					},
					{
						title: 'Cartoon Super Hero',
						description: '',
						img: { alt: 'Cartoon 5', src: '/img/Cartoon-Super-Hero.png' },
						li: { style: { gridColumn: 'span 3', gridRow: 'span 4' } }
					},
					{
						title: 'Cartoon Super Villain',
						description: '',
						img: { alt: 'Cartoon 6', src: '/img/Cartoon-Super-Villain.jpg' },
						li: { style: { gridColumn: 'span 3', gridRow: 'span 4' } }
					},
					{
						title: 'Dragon and Unicorn',
						description: '',
						img: { alt: 'Cartoon 2', src: '/img/Cartoon-Dragon-and-Unicorn.jpg' },
						li: { style: { gridColumn: 'span 8', gridRow: 'span 5' } }
					},
					{
						title: 'Dinosaurs',
						description: '',
						img: { alt: 'Cartoon 3', src: '/img/Cartoon-Dinosaurs.jpg' },
						li: { style: { gridColumn: 'span 7', gridRow: 'span 2' } }
					},
					{
						title: 'Farm Animals',
						description: '',
						img: { alt: 'Cartoon 7', src: '/img/Cartoon-Farm-Animals.png' },
						li: { style: { gridColumn: 'span 5', gridRow: 'span 1' } }
					},
					{
						title: 'Cars and Trucks',
						description: '',
						img: { alt: 'Cartoon 1', src: '/img/Cartoon-Cars-and-Trucks.jpg' },
						li: { style: { gridColumn: 'span 8', gridRow: 'span 2' } }
					},
				]
			}
		]
	},
	fantasy: {
		description: 'A new fantasy and sci-fi selection of bits and bobs',
		slug: '/fantasyandscifi',
		title: 'Fantasy/Sci-fi*',
		columns: 20,
		galleries: [
			{
				title: 'Fastasy',
				description: 'A fledgling selection of fastasy artwork',
				images: [
					{
						title: 'The Banquet Hall',
						description: 'The large feasting hall shrouded in darkness. From the dark ceiling dangle wicker shapes. At the far end of the hall can be seen the silhouette of the prankster demon...',
						img: { alt: 'Prankster Demom boss', src: '/img/Fantasy-The-Banquet.jpg' },
						li: { style: { gridColumn: 'span 8', gridRow: 'span 2' } }
					},
					{
						title: 'The Fallen Knight',
						description: 'The long dead knight is found slumped in the overgrown and sodden gardens...',
						img: { alt: 'Dungeons and Dragons boss', src: '/img/Fantasy-Fallen-Knight.jpg' },
						li: { style: { gridColumn: 'span 4', gridRow: 'span 2' } }
					},
					{
						title: 'The Prankster Demon Landscape',
						description: 'You emerge from the dense part of the forest just as a large gust of wind sweeps along an alpine meadow... The Prankster watches...',
						img: { alt: 'Dungeons and Dragons boss', src: '/img/Fantasy-Prankster-Landscape.jpg' },
						li: { style: { gridColumn: 'span 6', gridRow: 'span 1' } }
					},
					{
						title: 'Demonic Tree',
						description: 'A dead grey field clustered with giant tentacular roots spread out from a huge tree...',
						img: { alt: 'Dungeons and Dragons boss', src: '/img/Fantasy-Demonic-Tree.jpg' },
						li: { style: { gridColumn: 'span 6', gridRow: 'span 1' } }
					},
				]
			}
		]
	},
};
