import React from 'react';
import { Link } from 'react-router-dom';
const { GALLERIES } = require('./../data/galleries');
const YOUTUBE = {
	"feed": '',
		// "https://www.youtube.com/feeds/videos.xml?channel_id=PLsJ0rVuuw9SBcqRMdc3oTEaEK3P174hix",
		// "https://www.youtube.com/playlist?list=PLsJ0rVuuw9SBcqRMdc3oTEaEK3P174hix",
	"videos": [
		"https://www.youtube-nocookie.com/embed/4hlZ7NcViG0",
		// "https://www.youtube-nocookie.com/embed/videoseries?list=4hlZ7NcViG0&list=PLsJ0rVuuw9SBcqRMdc3oTEaEK3P174hix",
		// "https://www.youtube-nocookie.com/embed/-J0o6Pu1RmU",
		// "https://www.youtube-nocookie.com/embed/d114wi_ZGI4",
		// "https://www.youtube-nocookie.com/embed/videoseries?list=PLsJ0rVuuw9SA9R_k0LLgrKgABG_LdmTUY"
	]
}
const [ FEATURED_IMAGE ] = GALLERIES.places.galleries[0].images.filter(i => i.id === 'freedom-for-the-trees');

const Home = (props) => {
	window.scrollTo(0, Math.random() * 10);window.scrollTo(0, 0);
	return (
		<main>
			{ /* <section>
			<img src="/img/scanthis.png" alt="Please scan this to view my vaccination status of 'Mind your own business'" style={{ display: 'block', margin: 'auto' }} />
			</section> */ }
			<section style={ { backgroundImage: 'url(/img/the-lair-03.jpg)' } } className="appear">
				<h1>New Zealand Artist</h1>
				<p>Based in the middle of nowhere.</p>
			</section>
			<section className="boxout">
				<div className="appear">
					<img src="/img/Terrys-Bike.jpg" alt="Terry's Bike"/>
					<h2>Socially Awkward</h2>
					<p>Stranger in a Strange Land</p>
				</div>
			</section>
			<section className="picture">
				<div className="appear">
					<img src={ FEATURED_IMAGE.img.src } alt={ FEATURED_IMAGE.img.alt } />
					<h3>{ FEATURED_IMAGE.title }</h3>
					<p>{ FEATURED_IMAGE.description }</p>
					<Link to="/places">More places...</Link>
				</div>
			</section>
			<section className="breakout">
				<div className="appear">
					<p><big>Over the years the architectural and social nonsense has inspired images of the UK:</big></p>
					<p><em>... A desperately overcrowded country in rapid decline, where an out of date social welfare system and out of touch government inspire loneliness and disenfranchisement from the multitude that live there. How will the UK emerge from it's mid-life crisis? Will it rise like a phoenix from the ashes of it's involvement with the EU? Or will it just buy a Harley and crash it off a cliff..?</em></p>
					<p>I now live in New Zealand, which is pretty much as far away from the UK as I could get...</p>
				</div>
			</section>
			<section className="grid">
				{ Object.keys(GALLERIES).map(k => (
					<div className="appear" key={ k }>
						<h3><Link to={ GALLERIES[k].slug }>{ GALLERIES[k].title }</Link></h3>
						<p>{ GALLERIES[k].description } <Link to={ GALLERIES[k].slug }>...</Link></p>
					</div>
				)) }
				<div className="appear">
					<h3><Link to="/news">News</Link></h3>
					<p>What's new with me. <Link to="/news">...</Link></p>
				</div>
			</section>
			<section className="videos breakout">
				<div className="appear">
					{
						YOUTUBE.videos.map((item, index) => (
							<div className="video-container" key={ `video-${ item }`}>
								<iframe title="Player" id={`player${ index }`} src={ item } frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
							</div>
						))
					}
					<p>Videos are available on <a href="https://www.youtube.com/channel/UCOjIXjRO09QFjUipTK_VHQg">YouTube</a> and <a href="https://vimeo.com/user94405466">Vimeo</a>.</p>
				</div>
			</section>
		</main>
	);
};

export default Home;
