// import React from 'react';
// import { Link } from 'react-router-dom';
export const DRUM_LESSONS = [
	{
		title: "Lesson 1",
		media:[{
            title: 'Basic 4:4 Beat with Fills',
			src: 'mp4/001_001.mp4'
		}, {
            title: 'Single Stroke Rolls with accents',
			src: 'mp4/001_002.mp4'
		}],
    },
    {
        title: "Lesson 2",
        media:[{
            title: 'Basic 4:4 Beat with In-Between Bass Drums',
            src: 'mp4/002_001.mp4'
        }]
    }
];