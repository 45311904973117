import React from 'react';
export const COURSES = [
	{
		src: 'https://docs.google.com/forms/d/e/1FAIpQLSfP2LGySOAhdmwR0KVLsyj1peUVP9qH__sNzUS6AdefcCQF6w/viewform',
		title: 'Portrait Painting',
		description: (
			<>
				<p>The portrait painting course aims to teach the fundamentals of painting a face. Over the course, students will learn techniques to help them create a reasonable likeness. The course mainly focuses on learning about facial proportions and how to correctly position the features - as well as how to fix things when they go wrong!</p>
				<p>This is a beginners' course with a strong focus on building student confidence. It's aimed at students with little or no experience that just want to have a go.</p>
			</>
		)
	}
];
