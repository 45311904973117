import React from 'react';
import { Link } from 'react-router-dom';
export const NEWS = [
	{
		date: "2021-12-10",
		image:{
			src: 'img/Cartoon-Wolf-in-Sheeps-Clothing.jpg',
			alt: 'Craziness',
			caption: 'Covid Craziness'
		},
		body: (
			<>
				<p>The Covid madness has compelled me to vent some anger and frustration in cartoon form; Check them out <a href="/cartoons">here</a>.</p>
			</>
		)
	},
	{
		date: "2021-04-15",
		image:{
			src: 'img/courses.jpg',
			alt: 'Arapuni Art',
			caption: 'Cartoon Club at Arapuni Art'
		},
		body: (
			<>
				<p>Myself and Mandy started arts and craft courses in January and we've had lots of interest. Courses range from Oil Painting classes - landscape and portraiture to classes for kids, like the <a href="https://www.arapuniart.co.nz/courses/cartoon-club/">Cartoon Club</a> and <a href="https://www.arapuniart.co.nz/courses/holiday-activities-for-kids/">Holiday Activities for Kids</a>.</p>
				<p>Find out more and book places at our <a href="https://www.arapuniart.co.nz">Arapuni Art</a> website.</p>
			</>
		)
	},
	{
		date: "2020-09-01",
		image:{
			src: 'img/St-Alkmunds-2.jpg',
			alt: 'Fish Street',
			caption: 'Fish Street, Shrewsbury'
		},
		body: (
			<>
				<p>Continuing the theme of carrying on with golden oldies. This one is of Fish Street, Shrewsbury. Not sure exactly when I started this one, but likely to be around 2005.</p>
			</>
		)
	},
	{
		date: "2020-08-10",
		image:{
			src: 'img/Moreton-Corbet-2020.jpg',
			alt: 'Moreton Corbet',
			caption: 'Moreton Corbet, Shropshire'
		},
		body: (
			<>
				<p>Finally finishing, after starting in 1998. The building is Moreton Corbett mansion house in Shropshire, UK. Even though it's still not finished (when are they ever..?), it's certainly much closer. The house was damaged in the English Civil War and there were plans in the 1700s to restore it.</p>
				<p>I wonder if I'll finish it before they end up restoring it.</p>
			</>
		)
	},
	{
		date: "2020-08-01",
		image:{
			src: 'img/D3-Over-Compensating.jpg',
			alt: 'Over Compensating',
			caption: 'Over Compensating'
		},
		body: (
			<>
				<p>Sometimes the smile hides something else. The third in a set dealing with something that we don't talk about... Society tells us that it's ready, but is it..? Really? Really..? <Link to="/other">More...</Link></p>
			</>
		)
	},
	{
		date: "2020-04-11",
		image:{
			src: 'img/James-Dean.jpg',
			alt: 'James Dean',
			caption: 'James Dean'
		},
		body: (
			<p>A painting of Hollywood star and cultural icon of teenage disillusionment and social estrangement, James Dean.</p>
		)
	},
	{
		date: "2019-12-14",
		image:{
			src: 'img/Arapuni-Village-Hall-1000.jpg',
			alt: 'Arapuni Village Hall Mural, December 2019',
			caption: 'Arapuni Village Hall Mural'
		},
		body: (
			<>
				<p>A mural in Arapuni Village Hall for the upcoming 50th anniversary celebrations. You can read more on Stuff, <a href="https://www.stuff.co.nz/waikato-times/news/118367225/memories-of-south-waikato-halls-50-years-forgotten" rel="nofollow">here</a>.</p>
				<p><a href="https://www.stuff.co.nz/waikato-times/news/118367225/memories-of-south-waikato-halls-50-years-forgotten" rel="nofollow"><img alt="MOB with Trish at the Village Hall" src="/img/me-and-trish.jpg" /></a></p>
			</>
		)
	},
	{
		date: "2019-12-05",
		image:{
			src: 'img/Leamington-Primary-School-1000.jpg',
			alt: 'Helping Leamington Primary School, December 2019',
			caption: 'Leamington Primary School Mural'
		},
		body: (
			<>
				<p>I lent a helping hand to <a href="http://www.leamington.school.nz/" target="_blank" rel="noopener noreferrer">Leamington Primary School</a> with their mural.</p>
				<p>It was a project for one of the students. If your school is looking to do a mural, drop me an email and maybe I can help.</p>
			</>
		)
	},
	{
		date: '2019-10-01',
		image: {
			src: 'img/Shed-Dali-600.png',
			alt: 'Salvador Dali in progress on shed, 2019',
			caption: 'Salvador Dali'
		},
		body: (
			<p>Kicked off some shed painting with the inimitable Salvador Dali - the Spanish Surrealist renowned for his technical skill, precise draftsmanship and the striking and bizarre images in his work.</p>
		)
	}
];
