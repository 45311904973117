import React from 'react';

class Gallery extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedGallery: null,
			selectedIndex: null
		};
		this.select = this.select.bind(this);
	}
	select(gallery, imageIndex) {
		this.setState({
			selectedGallery: gallery,
			selectedIndex: gallery && (imageIndex > gallery.images.length -1 ? 0 : (imageIndex < 0 ? gallery.images.length - 1 : imageIndex) )
		});
	}
	componentDidMount() {
		document.addEventListener("keydown", this.onKeyPressed.bind(this));
	}
	componentWillUnmount() {
		document.removeEventListener("keydown", this.onKeyPressed.bind(this));
	}
	onKeyPressed(e) {
		const { state: { selectedIndex } } = this;
		if(e.keyCode === 39) { // RIGHT
			this.select(selectedIndex + 1);
		} else if(e.keyCode === 37) { // LEFT
			this.select(selectedIndex - 1);
		} else if(e.keyCode === 27) { // ESC
			this.select(null);
		} else {
			// console.log(e.keyCode);
		}
	}
	render() {
		const { props: { data: { title, galleries, columns } }, state: { selectedIndex, selectedGallery } } = this;
		const drawImages = (imgs) => imgs.map((image, i) => (
			<div key={ image.img.src } className={ i === selectedIndex ? 'selected' : null }>
				<img alt={ image.img.alt } src={ image.img.src } />
				<h3>{ image.title || image.img.alt }</h3>
				<p>{ image.description }</p>
			</div>
		));
		const drawGallery = (gallery) => (
			<div key={ gallery.title }>
				{ selectedIndex !== null && selectedGallery === gallery && (
					<div className="selectedImage">
						{ gallery.images && drawImages(gallery.images) }
						<ul>
							<li className="close" onClick={ () => this.select(null, null) }><i className="fa fa-sign-out" /></li>
							<li className="prev" onClick={ () => this.select(gallery, selectedIndex - 1)}><i className="fa fa-chevron-left" /></li>
							<li className="next" onClick={ () => this.select(gallery, selectedIndex + 1)}><i className="fa fa-chevron-right" /></li>
						</ul>
					</div>
				) }
				<ul style={ { gridTemplateColumns: `repeat(${ columns }, 1fr)` } } className="gallery">
					{ gallery.images.map((image, i) => (
						<li key={ image.img.src } {...image.li} onClick={ () => this.select(gallery, i) }>
							<img alt={ image.img.alt } src={ image.img.src } />
						</li>
					)) }
				</ul>
			</div>
		);
		return (
			<main>
				<h1>{ title }</h1>
				{ galleries.map(drawGallery) }
			</main>
		);
	}
};

export default Gallery;
