import React from 'react';

const EMBED = false;
const { COURSES } = require('./../data/courses');

class Courses extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedIndex: null
		};
		this.select = this.select.bind(this);
	}
	select(selectedIndex) {
		this.setState({ selectedIndex });
	}
	render() {
		const { state: { selectedIndex } } = this;
		window.scrollTo(0, Math.random() * 10);window.scrollTo(0, 0);
		return (
			<main>
				<section style={ { backgroundImage: 'url(/img/courses-header.jpg)' } } className="appear">
					<h1>Courses</h1>
					<p>Courses take place on our section in Arapuni. Maungatautari Mountain opposite watches our students' progress with interest!</p>
				</section>
				<section className="boxout">
					{ selectedIndex === null && COURSES.map((c, i) => (
						<div key={ c.title }>
							{ EMBED && <button onClick={ () => this.select(i) }>Sign up <i className="fa fa-long-arrow-right"></i></button> }
							{ !EMBED && <a href={ c.src } target="_blank" rel="noopener noreferrer">Sign up <i className="fa fa-long-arrow-right"></i></a> }
							<h2>{ c.title }</h2>
							<p>{ c.description }</p>
						</div>
					))}
				</section>
				{ EMBED && selectedIndex !== null && (
					<>
						<button onClick={ () => this.select(null) }>Cancel</button>
						<iframe src={ COURSES[selectedIndex].src } title={ COURSES[selectedIndex].title } />
				 	</>
				) }
			</main>
		)
	}
};

export default Courses;
