import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
  	 <BrowserRouter>
    	<App />
	</BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

(function($) {
	window._onscroll = (e) => {
		const body = document.getElementsByTagName('body')[0];
		const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
		const isInViewport = (elem) => {
			var buffer = 50;
			var bounding = $(elem).offset();
			bounding.bottom = bounding.top + $(elem).outerHeight();
			var viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
			return (
				(bounding.top + buffer >= scrollTop && bounding.top - buffer <= scrollTop + viewportHeight) ||
				(bounding.bottom + buffer >= scrollTop && bounding.bottom - buffer <= scrollTop + viewportHeight)
			);
		};
		$('.appear').each((idx, elem) => {
			if (isInViewport(elem) && !$(elem).hasClass('visible')) {
				$(elem).addClass('visible');
			} else if (!isInViewport(elem) && $(elem).hasClass('visible')) {
				$(elem).removeClass('visible');
			}
		});
		if(scrollTop > 100 && !$(body).hasClass('scrolled')) {
			$(body).addClass('scrolled');
		} else if (scrollTop <= 100) {
			$(body).removeClass('scrolled');
		}
	}
	$(window).on('scroll hashchange', window._onscroll).trigger('scroll');
})(window.jQuery);
