import React, { Component } from 'react';
import './scss/styles.scss';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import { DrumLessons, Home, Gallery, News } from './components';

const { GALLERIES } = require('./data/galleries');

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bodyClass: ''
		};
		// ['setBodyClass'].forEach(f => this[f] = this[f].bind(this));
	}
	render() {
		const { props: { location: { pathname } } } = this;
		const cleanPath = p => p.replace(/^\//, '').replace(/\//, '-');
		const bodyClass = cleanPath(pathname) || 'home';
		// return (
		// 		<div className={ `lost` }>Lost</div>
		// );
		return (
				<div className={ `App ${ bodyClass }` }>
					<header>
						<Link to="/">Mark Oliver Brawn</Link>
						<nav>
							<Link to="/" className={ bodyClass === 'home' ? 'active' : null }>Home</Link>
							{ Object.keys(GALLERIES).map(k => <Link key={ k } to={ GALLERIES[k].slug } className={ bodyClass === cleanPath(GALLERIES[k].slug) ? 'active' : null }>{ GALLERIES[k].title }</Link>) }
							<Link to="/news" className={ bodyClass.indexOf('news') !== -1 ? 'active' : null }>News</Link>
						</nav>
					</header>
					<Switch>
						<Route exact path="/" component={ () => <Home /> } />
						<Route exact path="/news" component={ () => <News /> } />
						<Route exact path="/drum-lessons" component={ () => <DrumLessons /> } />
						{ /*<Route exact path="/courses" component={ () => <Courses /> } />*/ }
						{ Object.keys(GALLERIES).map(k => <Route key={ k } exact path={ GALLERIES[k].slug } component={ () => <Gallery data={ GALLERIES[k] } /> } /> ) }
					</Switch>
					<footer>
						<a href="https://www.arapuniart.co.nz"><img src="/img/logo-arapuni-art.png" alt="Arapuni Art Logo" /></a>
						<p>&copy; Mark Oliver Brawn</p>
					</footer>
				</div>
		);
	}
}
export default withRouter((props) => <App {...props} />);
// export default App;
